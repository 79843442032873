import { forwardRef } from 'react';
import BaseInputTextarea from "../../sisu/form/BaseInputTextarea.sisu";

const NeoInputTextarea = forwardRef((props, ref) => {
    return <>


        < div className={`p-field p-p-1 p-col${props.extra ? props.extra : ""}${props.col ? ` p-col-${props.col}` : ` p-col-12`}${props.md ? `p-md-${props.md}` : ""}${props.sm ? ` p-sm-${props.sm}` : ""}`
        }>

            <span className={`p-float-label ${props.rightIcon ? 'p-input-icon-right': ''}`}>
                {props.rightIcon}
                <BaseInputTextarea {...props} ref={ref} className={`p-col-12 ${props.error ? 'p-invalid' : ''}`} autoResize />
                <label htmlFor={props.id}>{props.label}</label>
                {
                    props.error
                    && <small className="p-invalid p-d-block">
                        {props.error}
                    </small>
                }
                {props.children}
            </span>
        </div >
    </>

        ;
})

export default NeoInputTextarea;