import React from "react";

import { useState, useEffect } from "react";
import NeoDropdown from "../form/NeoDropdown.base";
import NeoCalendar from "../form/NeoCalendar.base";
import NeoDialog from "../overlay/NeoDialog.base";
import NeoGridContainer from "../layout/NeoGridContainer.base";
import NeoButtonMain from "../button/NeoButtonMain.base";

const yearsAgo = 3;

function getCurrentYear() {
  const now = new Date();
    return now.getFullYear();
}

function getMinYear() {
    return getCurrentYear() - yearsAgo;
}

function monthNumberToString(month) {
  return month === 1
    ? "Enero"
    : month === 2
    ? "Febrero"
    : month === 3
    ? "Marzo"
    : month === 4
    ? "Abril"
    : month === 5
    ? "Mayo"
    : month === 6
    ? "Junio"
    : month === 7
    ? "Julio"
    : month === 8
    ? "Agosto"
    : month === 9
    ? "Septiembre"
    : month === 10
    ? "Octubre"
    : month === 11
    ? "Noviembre"
    : month === 12
    ? "Diciembre"
    : null;
}

const minDate = new Date(`${getMinYear()}-01-01 00:00`);
const maxDate = new Date();

const periodTypesOptions = [
  { label: "Mes", value: "MONTH" },
  { label: "Año", value: "YEAR" },
  { label: "Personalizado", value: "CUSTOM" },
];

const yearsOptions = Array(yearsAgo + 1)
    .map((value, index) => ({ label: `${getCurrentYear() - index}`, value: getCurrentYear() - index }));

export default function PeriodFilterDialog(props) {
  const [selectedPeriodType, setSelectedPeriodType] = useState();
  const [filterPayload, setFilterPayload] = useState({});

  useEffect(() => reboot(), [props.value]);

  const reboot = () => {
    if (props.value && !props.value.period && props.value.label !== "") {
      const { type, payload } = getPropsValues();
      handleChangeFilter({ type, payload });
    }
  };

  const getPropsValues = () => {
    const { type, payload } = props.value;
    setSelectedPeriodType(type);
    setFilterPayload(payload);
    return { type, payload };
  };

  const handleChangeFilter = (options = {}) => {
    const type = options.type ?? selectedPeriodType;
    const payload = options.payload ?? filterPayload;
    const label = getFilterLabel(type, payload);
    const period = getFilterPeriod(type, payload);
    const periodISO = getFilterPeriodISO(type, payload);
    props.onChange({ type, payload, label, period, periodISO });
  };

  const getFilterLabel = (type, payload) => {
    if (type == "MONTH") {
      const { month } = payload;
      return `${monthNumberToString(
        month.getMonth() + 1
      )} ${month.getFullYear()}`;
    }
    if (type == "YEAR") {
      return `${payload.year}`;
    }
    if (type == "CUSTOM") {
      const fromDay = payload.from.getDate();
      const fromMonth = payload.from.getMonth() + 1;
      const toDay = payload.to.getDate();
      const toMonth = payload.to.getMonth() + 1;
      const from = `${fromDay < 10 ? "0" : ""}${fromDay}/${
        fromMonth < 10 ? "0" : ""
      }${fromMonth}/${payload.from.getFullYear()}`;
      const to = `${toDay < 10 ? "0" : ""}${toDay}/${
        toMonth < 10 ? "0" : ""
      }${toMonth}/${payload.to.getFullYear()}`;
      return `${from} - ${to}`;
    }
    return "Desconocido";
  };

  const getFilterPeriod = (type, payload) => {
    if (type === "MONTH") {
      const fromMonth = payload.month.getMonth() + 1;
      const fromYear = payload.month.getFullYear();
      const toMonth = fromMonth < 12 ? fromMonth + 1 : 1;
      const toYear = fromMonth < 12 ? fromYear : fromYear + 1;
      const toDate = new Date(
        new Date(`${toYear}-${toMonth}-1 00:00`).getTime() - 60000
      );
      const toDay = toDate.getDate();
      const from = `1/${fromMonth}/${fromYear}`;
      const to = `${toDay}/${fromMonth}/${fromYear}`;
      return { from, to };
    }
    if (type === "YEAR") {
      const { year } = payload;
      const from = `1/1/${year}`;
      const to = `31/12/${year}`;
      return { from, to };
    }
    if (type === "CUSTOM") {
      const from = `${payload.from.getDate()}/${
        payload.from.getMonth() + 1
      }/${payload.from.getFullYear()}`;
      const to = `${payload.to.getDate()}/${
        payload.to.getMonth() + 1
      }/${payload.to.getFullYear()}`;
      return { from, to };
    }
  };

  const getFilterPeriodISO = (type, payload) => {
    if (type === "MONTH") {
      let from = payload.month;
      let to = new Date(payload.month);
      to.setMonth(to.getMonth() + 1);

      return { from, to };
    }
    if (type === "YEAR") {
      const { year } = payload;
      const from = new Date(new Date(year, 0, 1)).toISOString();
      let to = new Date(new Date(year + 1, 0, 1)).toISOString();
      return { from, to };
    }
    if (type === "CUSTOM") {
      const from = new Date(payload.from);
      let to = new Date(payload.to);
      to = to.setDate(to.getDate() + 1);
      to = new Date(to).toISOString();
      return { from, to };
    }
  };

  const isValidFilter = () => {
    if (selectedPeriodType == "MONTH") {
      if (!filterPayload.month) {
        return false;
      }
    }
    if (selectedPeriodType == "YEAR") {
      if (!filterPayload.year) {
        return false;
      }
    }
    if (selectedPeriodType == "CUSTOM") {
      if (!filterPayload.from || !filterPayload.to) {
        return false;
      }
      if (filterPayload.from > filterPayload.to) {
        return false;
      }
    }
    return true;
  };

  const isDifferentFilter = () => {
    if (props.value) {
      const { type, payload } = props.value;
      if (selectedPeriodType == type) {
        for (const [key, value] of Object.entries(payload)) {
          const date = filterPayload[key];
          if (date && date.toString() != value.toString()) {
            return true;
          }
        }
        return false;
      }
    }
    return true;
  };

  const handleDateFilterTypeDropdownChange = (event) => {
    const selectedDateFilterType = event.value ?? event;
    setSelectedPeriodType(selectedDateFilterType);
    setFilterPayload({});
  };

  const elements = {
    footer: (
      <>
        {
          <>
            <NeoButtonMain
              label={"Aplicar"}
              disabled={!isValidFilter() || !isDifferentFilter()}
              onClick={() => handleAccept()}
            />
          </>
        }
      </>
    ),
  };

  const handleAccept = () => {
    handleChangeFilter();
    props.visibleSetter(false);
  };

  const handleCancel = () => {
    if (props.value && !props.value.period && props.value.label !== "") {
      getPropsValues();
    }
    props.visibleSetter(false);
  };

  return (
    <NeoDialog
      header="Filtrar por periodo de tiempo"
      visible={props.visible}
      closable={true}
      dismissableMask={true}
      onHide={handleCancel}
      footer={elements.footer}
    >
      <NeoDropdown
        label="Tipo de periodo"
        value={selectedPeriodType}
        options={periodTypesOptions}
        onChange={handleDateFilterTypeDropdownChange}
      />
      {selectedPeriodType == "MONTH" && (
        <NeoCalendar
          label="Mes"
          value={filterPayload.month}
          view="month"
          maxDate={maxDate}
          dateFormat="MM yy"
          yearNavigator={true}
                  yearRange={`${getMinYear()}:${getCurrentYear()}`}
          onChange={(event) => setFilterPayload({ month: event.value })}
        />
      )}
      {selectedPeriodType == "YEAR" && (
        <NeoDropdown
          label="Año"
          value={filterPayload.year}
          options={yearsOptions}
          onChange={(event) => setFilterPayload({ year: event.value })}
        />
      )}
      {selectedPeriodType == "CUSTOM" && (
        <>
          <NeoGridContainer subExtra="m-0">
            <NeoCalendar
              col="6"
              label="Desde"
              minDate={minDate}
              maxDate={maxDate}
              value={filterPayload.from}
              dateFormat="dd MM yy"
              monthNavigator={true}
              yearNavigator={true}
                          yearRange={`${getMinYear()}:${getCurrentYear()}`}
              onChange={(event) =>
                setFilterPayload({ ...filterPayload, from: event.value })
              }
            />
            <NeoCalendar
              col="6"
              label="Hasta"
              minDate={minDate}
              maxDate={maxDate}
              value={filterPayload.to}
              dateFormat="dd MM yy"
              monthNavigator={true}
              yearNavigator={true}
                          yearRange={`${getMinYear()}:${getCurrentYear()}`}
              onChange={(event) =>
                setFilterPayload({ ...filterPayload, to: event.value })
              }
            />
          </NeoGridContainer>
        </>
      )}
    </NeoDialog>
  );
}
